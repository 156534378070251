// $line: 1.25rem;
// $hairline: 0.125rem;
// $smallfont: 0.75rem;

// $bodywidth: 35rem;
// $asidewidth: 10rem;

// html {
//     font-size: 1em;
//     line-height: $line;

//     text-align: justify;
//     -moz-hyphens: auto;
//     -webkit-hyphens: auto;
//     -ms-hyphens: auto;
//     hyphens: auto;
// }

// body {
//     max-width: $bodywidth;
//     margin-left: auto;
//     margin-right: auto;
//     padding: 0 $line;
//     position: relative;
// }

// h1, h2, h3, h4, h5, h6 { margin: $line 0; }

// h1 { font-size: (2.00 * $line); line-height: (3.0 * $line); text-align: center; }
// h2 { font-size: (1.50 * $line); line-height: (1.0 * $line); }
// h3 { font-size: (1.25 * $line); line-height: (1.0 * $line); }
// h4 { font-size: (1.00 * $line); line-height: (1.0 * $line); }
// h5 { font-size: (0.75 * $line); line-height: (1.0 * $line); }
// h6 { font-size: (0.50 * $line); line-height: (1.0 * $line); }

// h2, h3 {
//     padding-top: (0.5 * $line);
//     padding-bottom: (0.5 * $line - $hairline);
//     border-bottom: $hairline solid;
// }

// p, div, ul, ol, blockquote, address, table, pre { margin: $line 0; }

// li p:first-child, dd p:first-child { margin-top: 0; }
// li p:last-child, dt p:last-child { margin-bottom: 0; }

// p+p { text-indent: $line; }

// ul, ol {
//     padding-left: 0;
//     margin-left: (2 * $line);
// }
// li ul, li ol { margin-top: 0; margin-bottom: 0; }

// dt { font-weight: bolder; margin-top: $line; }
// dd { margin-bottom: $line; margin-left: $line; }

// blockquote {
//     padding: 0 $line;
// }

// table { margin-left: auto; margin-right: auto; }

// thead { border-bottom: $hairline solid currentColor; }
// tfoot { border-top:    $hairline solid currentColor; }
// thead, tfoot {
//     th, td {
//         line-height: ($line - $hairline);
//     }
// }

// td, th { font-size: $smallfont; padding: 0 ($line / 2); }
// td:first-child, th:first-child { padding-left: 0; }
// td:last-child, th:last-child { padding-right: 0; }

// pre {
//     padding: $line 0;
//     border-left: $hairline solid;
//     padding-left: ($line - $hairline);
//     font-size: 0.75rem;
// }

// hr {
//     color: inherit;
//     margin: $line 0;
//     margin-top: (-1 * $hairline);
//     padding: 0;
//     border: none;
//     border-top: $hairline solid;
//     height: 0;
// }

// aside, nav {
//     font-size: $smallfont;
//     line-height: $line;

//     h1 { line-height: $line; }
//     h1 { font-size: 1rem; }
//     h2 { font-size: 0.875rem; }
//     h3 { font-size: 0.750rem; }
//     h2, h3 {
//         line-height: $smallfont;
//         padding-top: (($line - $smallfont) / 2);
//         padding-bottom: ((($line - $smallfont) / 2) - $hairline);
//         border-bottom: $hairline solid;
//     }
// }


// /* By default, navigation should appear inline */
// nav {
//     text-align: center;

//     /* Flatten the navigation hierarchy */
//     ul { margin-left: 0; }
//     ul, li { display: inline; }

//     /* Use vertical bars to separate each item. */
//     ul *:first-child:before { content: ''; }
//     ul *:before { content: ' | '; }
// }

// /*
//  * By default, asides should appear inline, but narrower because they have
//  * a smaller font size.
//  */
// aside {
//     max-width: ($asidewidth + $asidewidth);
//     margin-left: auto;
//     margin-right: auto;
//     padding: 0 $line;
// }

// /* If we have sufficient width to put stuff on the sides... */
// $asideoffset: ($line + $line + $asidewidth + $line);
// $bodyWithAsides: ($asideoffset + $bodywidth + $asideoffset);
// @media screen and (min-width: $bodyWithAsides) {

//     aside, nav {
//         margin: $line;
//         margin-top: 0;
//         padding: 0 $line;
//         outline: $hairline solid;

//         max-width: $asidewidth;
//         text-align: left;
//     }

//     aside {
//         clear: right;
//         float: right;
//         margin-right: (0 - $asideoffset);
//     }

//     nav {
//         text-align: left;
//         clear: left;
//         float: left;
//         margin-left: (0 - $asideoffset);

//         /* Now we have room to display the hierarchy */
//         ul, li { display: block; }
//         ul ul { margin-left: $line; }

//         ul *:before { content: ''; }
//     }
// }

// /* If we don't even have our minimum width, don't bother justifying. */
// @media screen and (max-width: $bodywidth) {
//     html { text-align: left; }
// }

// /* Special support for footnotes from Pandoc */
// div.footnotes { font-size: $smallfont; }



html {
  height: 100%;
}

body {
  background: #242A4E;
  color: #ccc;
  font-family: "futura-pt", sans-serif;
  min-height: 100%;
}



.wrapper.about-me {
  //background: url('../img/background.jpg') no-repeat center center;
  //background-size: cover;
  height: 100%;
  width: 90%;
  max-width: 90%;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.wrapper * {
  max-width: 100%;
}

.about-image {
  width: 40%;
  max-width: 40vmin;
}

.about-text {
  width: 50%;
  margin-left: 5%;
}

html.about {
  height: 100%;
}

.no-touchevents body.about {
  background: url('../img/background.jpg') no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.photo-of-daniel {
  width: 30%;
  background: url('../img/DanielBachler.jpg') no-repeat bottom center;
  background-size: cover;
}

.page-content {
  overflow: auto;
  width: 100%;
  padding-top: 200px;
}

.site-header{
  display: inline;
  font-family: "Ostrich Sans Inline", sans-serif;
  font-size: 80px;

  height: 140px;
  background: inherit;
  width: 100%;
  position: fixed;
  z-index: 10;
}

@include media-query($on-wide-screen) {
  .site-header {
    font-size: 60px;
  }

  .site-header {
    height: 90px;
  }

  .page-content {
    padding-top: 90px;
  }

}

@include media-query($on-laptop) {
  .site-header {
    font-size: 40px;
  }
}

@include media-query($on-palm) {
  .site-header {
    font-size: 30px;
  }

  .site-header {
    height: 50px;
  }

  .page-content {
    padding-top: 50px;
  }

  body {
    font-size: 14px;
  }

  pre, code {
    font-size: 12px;
  }

  .wrapper.about-me {
    display: block;
  }

  .about-me .about-image {
    max-width: 100%;
    width: 100%;
    margin-bottom: 2em;
  }

  .about-me .about-text {
    width: 100%;
    margin: 0;
  }
}

.page-link + .page-link {
  margin-left: 0.4em;
}

p + p {
  margin-top: 0.7em;
}

.site-nav {
  margin-right: 0.5em;
  float: right;
}

.site-title {
  margin-left: 0.5em;
}


main {
  max-width: 900px;
  margin: auto;
  line-height: 1.6;
}

.author {
  opacity: 0.5;
  font-size: 0.85em;
}

h1 {
  margin-bottom: 0;
}

article + article {
  margin-top: 2.5em;
}

p {
  letter-spacing: 0.5px;
}

p a {
  border-bottom: thin #999 dotted;
}

.pagination {
  margin-top: 2.5em;
  text-align: center;
}

p img {
  display: block;
  margin: 0 auto;
  height: auto;
}

p + h3, p + h2, p + h4, p + h5 {
  margin-top: 1.5em;
}

.attribution {
  margin-top: 3em;
  font-size: 0.8em;
  opacity: 0.7;
}

.contacts li {
  list-style: none;
  display: inline;
  margin: 8px;
  opacity: 0.5;
}

.contacts li:hover {
  opacity: 1.0;
}


.contacts li svg {
  height: 32px;
  width: 32px;
}

.icon {

  > svg {
    display: inline-block;
    width: 32px;
    height: 32px;
    vertical-align: middle;

    path {
      fill: #fff;
    }
  }
}

.cv-table {
  td {
    vertical-align: top;
    padding: 0.2em;
  }

  .cv-time {
    min-width: 6em;
  }
}

ul {
    list-style: disc;
}